/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    object, func, string, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';

import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import { pmallPluginConfig, getPmallURL } from '../../../../../../../../state/ducks/Common/ducks/PmallPersonalization/PmallPersonalization-Helper';
import { removePmallItem, setPmallPersonalizationData, updateCartPmallPersonalization } from '../../../../../../../../state/ducks/Common/ducks/PmallPersonalization/PmallPersonalization-Actions';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { updateOrder } from '../../../../../../../../state/ducks/Checkout/ducks/Cart/ducks/UpdateCart/UpdateCart-Actions';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: 'unset', // default margin too big for mobile so remove
    },
    cardMediaRoot: {
        height: '90vh',
        width: '980px',
        border: 'none',
        maxHeight: '80vh',

        [theme.breakpoints.down('lg')]: {
            width: '800px',
        },

        [theme.breakpoints.down('md')]: {
            width: '575px',
        },

        [theme.breakpoints.down('sm')]: {
            width: '400px',
        },

        [theme.breakpoints.down('xs')]: {
            width: '346px',
        },
    },

    closeButtonRoot: {
        '&:hover, &:focus, &:active': {
            cursor: 'pointer',
        },
    },
}));

const PmallPersonalizationDialog = ({
    product,
    pageType,
    isOpen,
    handleModalPmall,
}) => {
    const featureFlags =  useSelector(getFeatureFlags);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    if (!featureFlags['is-pmall-enabled']) {
        return null;
    }
    const { personalization = {}, cartItemId, productItem = {} } = product;
    const sku = productItem.skuCode;
    const refnum = personalization.personalizationId;
    const brand = product?.brandId;
    const url = getPmallURL({ sku, refnum, brand });
    const dispatch = useDispatch();
    /**
     * Wrap this in catch as its 3rd party and maybe
     * iframe stops sending data or errors out iteself?
     */
    const pmallListener = (event) => {
        const { sdkURL } = pmallPluginConfig;
        if (event.origin !== `https://${sdkURL}`) return;
        const reqObj = {
            orderItemId: cartItemId,
        };
        const updateOrderProps = {
            page: pageType,
        };
        let parsedData;
        try {
            parsedData = JSON.parse(event.data);
            if (parsedData?.action === 'savemodal' && parsedData.postdata) {
                dispatch(setPmallPersonalizationData(parsedData.postdata));
                if (pageType === 'pdp') {
                    handleModalPmall(false);
                } else if (pageType) {
                    dispatch(
                        updateCartPmallPersonalization({
                            cartItemId,
                            refnum: parsedData.postdata.refnum,
                            description: parsedData.postdata.description,
                            previewUrl: parsedData.postdata.imageURL,
                            onCloseModal: handleModalPmall,
                        }),
                    );
                    dispatch(updateOrder(reqObj, updateOrderProps));
                    if (window.addEventListener) {
                        window.removeEventListener('message', pmallListener, false);
                    } else if (window.detachEvent) {
                        window.detachEvent('onmessage', pmallListener);
                    }
                }
            }
        } catch (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                component: 'PmallPersonalizationDialog.js',
                message: 'Error parsing Pmall iFrame data',
                error,
            });
            parsedData = { postdata: { refnum: '' } };
            dispatch(removePmallItem());
            handleModalPmall(false);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            if (window.addEventListener) {
                window.removeEventListener('message', pmallListener, false);
            } else if (window.detachEvent) {
                window.detachEvent('onmessage', pmallListener);
            }
        } else if (typeof window !== 'undefined') {
            if (window.addEventListener) {
                window.addEventListener('message', pmallListener, false);
            } else {
                window.attachEvent('onmessage', pmallListener);
            }
        }
        return () => {
            window.removeEventListener('message', pmallListener);
            if (window.detachEvent) {
                window.detachEvent('onmessage', pmallListener);
            } else {
                window.removeEventListener('onmessage', pmallListener);
            }
        };
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            maxWidth={isMobile}
            disableBackdropClick // disable close outside for only clean the edit changes is the user close using the X button
            scroll="paper"
            onEntered={() => {
                dispatch(trackEvent({
                    eventCategory: (pageType === 'pdp') ? 'Product Page' : 'Checkout',
                    eventAction: 'PersonalizationMall Modal',
                    eventLabel: !refnum ? 'Open' : 'Edit',
                }));
            }}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogActions>
                <Close
                    data-testid="close-pmall-dialog-button"
                    className={classes.closeButtonRoot}
                    onClick={() => { handleModalPmall(false); }}
                />
            </DialogActions>

            <CardMedia
                className={classes.cardMediaRoot}
                component="iframe"
                src={url}
            />

        </Dialog>
    );
};

PmallPersonalizationDialog.propTypes = {
    product: object,
    isOpen: bool.isRequired,
    pageType: string,
    handleModalPmall: func.isRequired,
};
PmallPersonalizationDialog.defaultProps = {
    product: {},
    pageType: 'pdp', // pdp or checkout
};

export default PmallPersonalizationDialog;
