/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpUtil from 'mbp-api-util';

// Plugin Config
const pmallPluginConfig = {
    sdkURL: mbpUtil.getEnv('REACT_APP_PMALL_HOST'),
};

const pmallBaseURL = '/product/item/';

// shouldnt change unless pmall specified
let partnerId = 8;

const getPmallURL = (config) => {
    const {
        sku, refnum, mobileapp = '', brand,
    } = config;

    if (brand === '1033') {
        partnerId = 16;
    } else if (brand !== '1033' && brand !== '1030' && brand !== '1001') {
        // This HD personalization
        partnerId = 18;
    }
    const isNew = refnum === null || refnum === '' || refnum === undefined;
    const { sdkURL } = pmallPluginConfig;

    let pmallIframeURL = `https://${sdkURL}${pmallBaseURL}?sku=${sku}&partnerId=${partnerId}&mobileapp=${mobileapp}`;

    if (!isNew) {
        pmallIframeURL += `&refnum=${refnum}`;
    }

    pmallIframeURL += '&region=us';

    return pmallIframeURL;
};

export {
    pmallPluginConfig,
    getPmallURL,
};

export default {};
